import middleware from "modules/Partners/middleware";
import feed from "../feed";
import fetchEntityCell from "modules/Core/state/reusableCells/fetchEntityCell";
import { ALLIES } from "modules/Partners/consts";
import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { call } from "redux-saga/effects";
import { putSuccess } from "modules/Core/state/utils";

const { fetchAnalytics, fetchBalance } = middleware.operations;

export const fetchAnalyticsCell = fetchEntityCell(ALLIES, {
    requestHandler: fetchAnalytics,
    name: "fetchAnalytics",
    selector: state => state?.[ALLIES]?.analytics,
    idKey: "partnerId",
    successCell: {
        reducer: (state, { payload }) => {
            state.isLoading = false;
            state.analytics = payload;
        },
    },
});

export const fetchBalanceCell = initCell(ALLIES, {
    name: "fetchBalance",
    selector: state => state?.[ALLIES]?.balance, 
    sagas: {
        latest: function* ({payload}){
            const res = yield call(fetchBalance, {id: payload?.partnerId});
            yield putSuccess(ALLIES, "fetchBalance", res?.partner?.financialsAccount?.balance);

        }
    },
    successCell: {
        reducer: (state, {payload}) => {
                state.balance = payload;
            }
        }
    
})

export const {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters,
} = feed.cells

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    fetchAnalytics: fetchAnalyticsCell,
    fetchBalance: fetchBalanceCell,
    setFilters
}