import { useMemo } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import ExpandableDetailsSet from "../../ExpandableDetailsSet";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { withLoader } from "modules/Core/higher-order-components/withLoader";
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { actions, selectors, useLogic } from "..";
import Features from "modules/Core/components/ui-kit/Features";
import Card from "modules/Core/components/ui-kit/Card";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";

export const useFormatCampaignFeatureDetails = ({ vision, details, parent } = {}) => useMemo(() => [
    { name: "Vision Statement", content: vision },
    { name: 'Parent Campaign', content: parent?.id && <RedirectButton text={parent?.name} to={`/campaigns/${parent?.id}/gateway`} /> },
    { name: 'Values', content: details?.values?.map(({ text }) => <Card>{text}</Card>) },
    { name: 'Aims', content: <ExpandableDetailsSet entities={details?.aims} /> },
    { name: "Background", content: details?.background },
    { name: 'Mission', content: details?.mission },
    { name: 'Commitment', content: details?.commitment },
    { name: 'Location', content: details?.location?.map(({ city, countryCode }) => <Container>{city}</Container>) },
    { name: 'Tags', content: details?.tags?.map(({ name }) => name) },
], [details])

export const CampaignDetailsTabComponent = ({
    parent,
    aims,
    description,
    details,
    vision,
    ...props
}) => {

    const features = useFormatCampaignFeatureDetails({
        vision,
        details,
        parent
    })
    return (
        <Features
            features={features}
            card
            alignCenter
            maxWidth
        />
    )
}


export default strappedConnected(
    withLoader(
        CampaignDetailsTabComponent,
        fetchEntity.selectors.getIsLoading
    ),
    selectors,
    actions,
    useLogic,
);