import React, { useMemo } from 'react';
import Redirect from 'modules/Core/components/navigation/Redirect';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import JoinCampaignButton from '../../JoinCampaignButton';
import { SubcampaignsFeed } from "./ViewCampaign.panels";
import CampaignsPosts from '../../CampaignPosts';
import CampaignPartnershipsFeed from 'modules/Partners/submodules/campaign-partnerships/components/CampaignPartnershipsFeed';
import CampaignScreen from '../../CampaignScreen';
import CampaignDetailsTab from '../../CampaignDetails/CampaignDetailsTab';
import CampaignAdditionalInformation from '../../widgets/CampaignAdditionalInformation';
import CampaignContactInformation from '../../widgets/CampaignContactInformation';
import CampaignFoundingPartnerWidget from '../../widgets/CampaignFoundingPartnerWidget';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import CampaignDonationsWidget from '../../widgets/CampaignDonationsWidget';
import CampaignMembershipWidget from '../../widgets/CampaignMembershipWidget';

const back = {
    to: "/campaigns",
    text: "To Campaigns"
}

const tabs = [
    { title: "Posts", Component: CampaignsPosts },
    { title: "Details", Component: CampaignDetailsTab },
    { title: "Sub-Campaigns", Component: SubcampaignsFeed },
    { title: "Partners", Component: ({ id }) => <CampaignPartnershipsFeed campaignId={id} /> },
    { title: 'Fundraising', Component: ({ id }) => <Redirect to={`/campaigns/${id}/fundraising`} />, financialsAccountRequired: true }
]

const leftWidgets = [
    { Component: CampaignFoundingPartnerWidget, gridRow: '2', },
    { Component: CampaignAdditionalInformation, gridRow: '6', tabIndexFilter: 1 },
    { Component: CampaignContactInformation, gridRow: '4/5', tabIndexFilter: 1 },
    {
        Component: CampaignMembershipWidget,
        gridRow: 7
    }
];

const rightWidgets = [
    // { Component: EditCampaignButton, gridRow: '1', props: { panel: true, widget: true } },
    { Component: CampaignDonationsWidget, gridRow: '4/5' },
    { Component: JoinCampaignButton, gridRow: '1', props: { panel: true, widget: true } }
];

const filterTabs = (hasFinancialsAccount) => tabs.filter(tab => (!tab.financialsAccountRequired || hasFinancialsAccount));

export default strappedConnected(
    CampaignScreen,
    {
        financialsAccount: (state, { id }) => fetchEntity.selectField(id, 'financialsAccount')(state),
    },
    {},
    ({ financialsAccount, id }) => ({
        tabs: useMemo(() => filterTabs(Boolean(financialsAccount)), [financialsAccount]),
        forward: useMemo(() => ({
            to: `/campaigns/${id}/campsite`,
            text: "To Campsite",
            membershipRequired: true,
            restrictionMessage: "You must be a member of this campaign to enter the campsite."
        }), [id]),
        header: "Campaign Gateway",
        leftWidgets,
        rightWidgets,
        back,
    })

);