import { useMemo } from "react";
import { fetchEntity } from "modules/Partners/state/cells";
import Screen from "modules/Core/components/ui-kit/Screen";
import PartnerIdentificationCard from "../../PartnerIdentificationCard";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import Container from "modules/Core/components/ui-kit/Container";
import _tabs from "./index.tabs";
import withTracking from "modules/Core/higher-order-components/withTracking";
import { TRACKING_EVENTS } from "modules/Core/sub-modules/Tracking/consts";
import CampaignPartnershipInvitationButton from "../../widgets/CampaignPartnershipInvitationButton";
import PartnerContactInformation from "../../widgets/PartnerContactInformation";
import PartnerDonationsWidget from "../../widgets/PartnerDonationsWidget";
import ActivatePartnerFinancials from "../../widgets/ActivatePartnerFinancials";
import LaunchPartnerCampaign from "../../widgets/LaunchPartnerCampaign";
import PartnerFinancialsBalance from "../../widgets/PartnerFinancialsBalance";
import { useQueryParams } from "modules/Core/hooks/useStripQueryParams";
import styles from "./styles.module.scss";

const Header = ({ name, image }) => (
    <Container maxHeight alignCenter flex>
        <PartnerIdentificationCard
            name={name}
            image={image}
        />
    </Container>
)


const rightWidgets = [
    {
        Component: CampaignPartnershipInvitationButton,
        gridRow: 1,
        tabIndexFilter: 4,
    },
    {
        Component: PartnerDonationsWidget,
        gridRow: '3/4'
    },
    {
        Component: LaunchPartnerCampaign,
        gridRow: 8
    },
    {
        Component: ActivatePartnerFinancials,
        gridRow: '7'
    }
];

const leftWidgets = [
    {
        Component: PartnerContactInformation,
        gridRow: 5,
        tabIndexFilter: 1

    },
    {
        Component: PartnerFinancialsBalance,
        tabIndexFilter: 3,
        gridRow: '3/4'
    }
];

const back = {
    text: 'Back',
    to: true,
    navigate: -2
}

const tab2Index = {
    'info': 1
}

const TrackedPartnerProfileComponent = withTracking(
    Screen,
    { name: TRACKING_EVENTS.PARTNER_PROFILE_VIEW, trigger: "onload" },
    {},
    ({ id }) => ({ partnerId: id })
);


const filterTabs = (kind, associated, financiallyActive) => {
    const set = _tabs[kind];
    return set?.
        filter(tab => !(tab.hasOwnProperty('associatedOnly')) || (tab.associatedOnly && Boolean(associated)))?.
        filter(tab => !(tab.hasOwnProperty('financiallyActiveOnly')) || (tab.financiallyActiveOnly && Boolean(financiallyActive)))
}

export default strappedConnected(
    TrackedPartnerProfileComponent,
    {
        partner: (state, { id }) => fetchEntity.selector(id)(state)
    },
    {
        fetchEntity: fetchEntity.action
    },
    ({ partner, id, fetchEntity }) => {
        useOnLoad(() => fetchEntity({ id }), Boolean(id), [id]);
        const params = useQueryParams();

        const tabSettings = useMemo(() => ({
            header: true,
            activeTabIndex: Boolean(params?.activeTab) ? tab2Index[params?.activeTab] : 0
        }), [params?.activeTab]);

        const tabs = useMemo(() => filterTabs(partner?.kind, partner?.associated, Boolean(partner?.financialsAccount)), [partner?.kind, partner?.associated, partner?.financiallyActive]);

        const commonProps = useMemo(() => ({
            partnerId: id,
            associated: partner?.associated
        }), [partner?.name, partner?.info?.image, id,]);

        return {
            tabs,
            mainProps: commonProps,
            tripanel: true,
            rightWidgets,
            leftWidgets,
            header: 'Partner Profile',
            className: styles.container,
            widgetProps: commonProps,
            tabProps: commonProps,
            contentHeader: <Header name={partner?.name} image={partner?.info?.image} />,
            back,
            tabSettings,
        }
    }
);
