import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const setCampaignMutation = gql`
  mutation setCampaign($input: CampaignInput, $id: String) {
    entity: setCampaign(id: $id, input: $input) {
      name
      createdAt
      id
    }
  }
`;

export const CAMPAIGN_DETAILS_FRAGMENT = `
    name
    id
    vision
    photo
    coverPhoto
    approved {
        user
        time
    }
    creators
    details {
        aims {
            aim
            description
        }
    
        values {
            text
        }
        background
        commitment
        mission
        tags {
            name
            id
        } 
        location {
            city
            countryCode
        }
        contactInformation {
            email
            phoneNumber
            fullName
        }
        links {
            url
            name
        }
    }
    parent {
        name
        id
    }
`;

const fetchCampaignQuery = gql`
    query fetchCampaign($id: String!){
        entity: campaign(id: $id){
            ${CAMPAIGN_DETAILS_FRAGMENT}
            isMember
            isCampaignFounder
            membership {
                roles
                information {
                  bio
                  firstName
                  lastName
                  photo
                  id
                }
            }
            foundingPartner {
                name
                id
                info {
                    bio
                    image
                }
            }
            financialsAccount {
                stamps {
                  created
                  approved
                }
            }
          }
        }
`;

const publicCampaignQuery = gql`
    query fetchCampaign($id: String!){
        entity: publicCampaign(id: $id){
            ${CAMPAIGN_DETAILS_FRAGMENT}
        }
    }
`;

const fetchCampaignsQuery = gql`
    query fetchCampaigns(
      $feedParams: FeedParams,
       $parentId: String, 
       $partnerId: String,
       $search: String,
       $isRootCampaign: Boolean, 
       $categoryTags: [String]
       ) {
        feed: campaigns(
          feedParams: $feedParams,
          parentId: $parentId, 
          partnerId: $partnerId,
          search: $search,
          isRootCampaign: $isRootCampaign,
          categoryTags: $categoryTags
           ) {
            entities {
                id
                name
                approved {
                    user
                    time
                }
                            
                vision
                photo
                vision
                photo
                details {
                    background
                    tags {
                        name
                        id
                    }
                }
                parent {
                    name
                    id
                    parent {
                        name
                        id
                    }
                }
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;

const joinCampaignMutation = gql`
  mutation setCampaignMembership($campaignId: String, $activate: Boolean) {
    result: setCampaignMembership(
      campaignId: $campaignId
      activate: $activate
    ) {
      user
      id
      campaign
      isActive
    }
  }
`;

const permitProspectiveCampaignMutation = gql`
  mutation permitProspectiveCampaign(
    $campaignId: String!
    $isPermitted: Boolean! = true
  ) {
    permitProspectiveCampaign(
      campaignId: $campaignId
      isPermitted: $isPermitted
    ) {
      id
      name
    }
  }
`;

const setCampaignMembershipInformationMutation = gql`
  mutation setCampaignMembershipInformationMutation(
    $campaignId: String!
    $input: UserInformationInput!
  ) {
    setCampaignMembershipInformation(campaignId: $campaignId, input: $input) {
      id
    }
  }
`;

export const setCampaign = async (input, id) =>
  graphqlClient.request(setCampaignMutation, { input, id });
export const fetchCampaign = async (id) => {
  const q = graphqlClient.hasToken ? fetchCampaignQuery : publicCampaignQuery;
  return graphqlClient.request(q, { id });
};
export const fetchCampaigns = async (params) =>
  graphqlClient.request(fetchCampaignsQuery, params);
export const joinCampaign = async (payload) =>
  graphqlClient.request(joinCampaignMutation, payload);

export const permitProspectiveCampaign = async (campaignId, isPermitted) => {
  return graphqlClient.request(permitProspectiveCampaignMutation, {
    campaignId,
    isPermitted,
  });
};

export const setCampaignMembershipInformation = async (campaignId, input) =>
  graphqlClient.request(setCampaignMembershipInformationMutation, {
    campaignId,
    input,
  });
