import cx from 'classnames';
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import displayUserGuide from "modules/Core/sub-modules/Dialog/state/cells/displayUserGuide";
import Button from "modules/Core/components/ui-kit/Button"
import withShouldRender from "modules/Core/higher-order-components/withShouldRender"
import styles from "./styles.module.scss"
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';
const Component = withShouldRender(Button);

export default strappedConnected(
    Component,
    {
        settings: authorizeToken.selectors.getUserSettings
    },
    { displayUserGuide: (key) => displayUserGuide.action({ key }) },
    ({ displayUserGuide, userGuideKey, className, settings }) => {
        return ({
            onClick: (() => displayUserGuide(userGuideKey)),
            text: '?',
            shouldRender: Boolean(settings?.displayUserGuide && userGuideKey),
            className: cx(styles.button, className),
        })
    }
);
