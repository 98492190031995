import { useMemo } from "react";
import Feed from "modules/Campaigns/components/CampaignsFeed";
import Title from "modules/Core/components/ui-kit/Title";
import Container from "modules/Core/components/ui-kit/Container";
import ExpandableFeedItem from "modules/Core/components/Feed/ExpandableFeedItem";
import Date from "modules/Core/components/ui-kit/Date";
import CampaignFeedItemDetails from "../CampaignFeedItemDetails";
import fist from 'modules/Campaigns/assets/fist.svg'
import Tag from "modules/Core/components/ui-kit/Tag";
import styles from "./styles.module.scss";

const Header = ({ parent, name, approved, toggle, photo, }) => {
    return (
        <Container maxWidth className={styles.itemHeader} onClick={toggle}>
            <Container maxWidth flex alignCenter spaceBetween>
                <Container flex alignCenter>
                    <img src={photo ?? fist} />
                    <Title Element='h4' >
                        {/* {heritage} */}
                        {name}
                    </Title>
                </Container>
                <Container flex col spaceBetween>
                    {parent && <Tag nature={'bordered-ocean-blue'} size={'sm-md'} text={'sub-campaign'} />}
                    <Date className={styles.approved} date={approved?.time} />
                </Container>
            </Container>
        </Container>
    )
}

export const ItemComponent = ({ name, focusedId, parent, text, description, onClick, approved, photo, unFocus, ...props }) => {
    const isFocused = useMemo(() => focusedId === props?.id, [focusedId, props?.id]);
    return (
        <ExpandableFeedItem
            {...props}
            HeaderComponent={Header}
            headerProps={{
                name,
                parent,
                approved,
                photo
            }}
            relative
            toggle={() => unFocus?.()}
            ChildComponent={CampaignFeedItemDetails}
            onClick={() => !isFocused && onClick(props.id)}
            isOpen={isFocused}
            isControlled
        />
    );
}

const filters = { parentId: null };
const FeedPanel = ({ className, onClick, focusedId, unFocus }) => {
    const feedProps = useMemo(() => ({
        onClick,
        ItemComponent,
        itemProps: { focusedId, unFocus }
    }), [onClick, focusedId]);
    return (
        <Feed
            feedProps={feedProps}
            className={className}
            feedItemClassName={styles.feedItem}
            {...filters}
        />
    )
}

export default FeedPanel