import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthForm from "modules/Auth/components/forms/AuthForm"
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer"
import { useIsAuthenticated } from "modules/Auth/hooks/useConnectedDispatch"
import Form from "modules/Core/components/form/Form"
import { Navigation } from "modules/Core/components/ui-kit/Screen/ScreenSidePanel/ScreenNavigation/index.jsx"
import { AUTH } from "modules/Auth/consts"
import CenteredCardScreen from "modules/Core/components/ui-kit/Screen/CenteredCardScreen"
import useTabIndex from "modules/Core/hooks/useTabIndex"
import { formatPhoneNumberDiscretely } from "modules/Core/utils/phone-number"
import NextButton from "modules/Core/components/ui-kit/Button/NextButton/index.jsx"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected.jsx"
import setUserInformation from "modules/Auth/state/cells/setUserInformation.js"
import Container from "modules/Core/components/ui-kit/Container/index.jsx"
import { createEntity } from "modules/Partners/state/cells/index.js"
import setFormField from "modules/Auth/state/cells/setFormField.js"
import { parsePayload } from "modules/Partners/components/PartnerOnboardingForm/index.jsx"
import { PARTNER_KINDS } from "modules/Partners/consts/index.js"
import clearForm from "modules/Auth/state/cells/clearForm.js"
import { parseUserInformationInput } from "modules/Auth/components/UserInformationForm/index.jsx";
import schema from "./schema.js"
import styles from "./styles.module.scss"

const RegisterPartnerScreen = ({ setUserInformation, setPartnerInformation }) => {
    const isAuthenticated = useIsAuthenticated()
    const { next, activeTabIndex, setActiveTabIndex } = useTabIndex(0);

    const [phoneNumber, setPhoneNumber] = useState('');

    const _subheading = useMemo(() => ([
        "Type your phone number to authenticate",
        `Enter security code to verify ${phoneNumber} and login.`
    ][activeTabIndex]), [activeTabIndex, phoneNumber]);

    const onCodeSent = useCallback((phoneNumber) => {
        next()
        setPhoneNumber(
            formatPhoneNumberDiscretely(phoneNumber)
        )
    }, [next, setPhoneNumber]);

    useEffect(() => {
        isAuthenticated && setActiveTabIndex(2)
    }, [isAuthenticated])

    const submitPartnerForm = useCallback((input) => {
        setPartnerInformation()
        next();
    }, [next, setPartnerInformation])

    const submitIndividualForm = useCallback((input) => {
        setUserInformation()
        next();
    }, [next, setUserInformation])

    const nextOptions = useMemo(() => ({
        NEXT: next,
        SUBMIT_PARTNER_FORM: submitPartnerForm,
        SUBMIT_INDIVIDUAL_FORM: submitIndividualForm,
    }), [next, submitPartnerForm, submitIndividualForm]);

    const navigate = useNavigate();

    const onBack = useCallback((backKey) => {
        if (backKey === 'NAVIGATE_BACK') {
            return navigate(-1)
        }
        return setActiveTabIndex(activeTabIndex - 1)
    }, [activeTabIndex, setActiveTabIndex]);

    const [subheading, className, onNext, nextButtonText, btnProps, cardHeader, onBackKey] = useMemo(() => ([
        schema[activeTabIndex].subheading,
        schema[activeTabIndex].className,
        nextOptions[schema[activeTabIndex].onNext],
        schema[activeTabIndex].nextButtonText,
        schema[activeTabIndex].buttonProps,
        schema[activeTabIndex].cardHeader,
        schema[activeTabIndex].onBack,
    ]), [activeTabIndex, nextOptions]);

    const formSettings = useMemo(() => ({
        moduleName: AUTH,
        useUnifiedState: true,
    }), []);

    const cardHeaderProps = useMemo(() => ({
        children: onBackKey && <Navigation dir='left' onClick={(() => onBack(onBackKey))} />,
        className: styles.cardHeader
    }), [onBackKey, onBack]);


    return (
        <CenteredCardScreen
            header={"People 4 Progress"}
            cardHeader={cardHeader}
            onNext={false}
            subheading={isAuthenticated && subheading}
            subHeader={subheading ?? _subheading}
            className={styles.container}
            cardHeaderProps={cardHeaderProps}
            background
            back
        >
            <ConditionalContainer shouldRender={Boolean(!isAuthenticated)}>
                <AuthForm
                    className={styles.authForm}
                    redirect={false}
                    onCodeSent={onCodeSent}
                    ButtonComponent={NextButton}

                />
            </ConditionalContainer>
            <ConditionalContainer
                className={styles.contentContainer}
                shouldRender={Boolean(isAuthenticated)}
                maxHeight
                maxWidth
            >
                <Form
                    tabs={schema}
                    activeTabIndex={activeTabIndex}
                    options={formSettings}
                />
                <Container flex flexEnd center mt3 pb1>
                    <NextButton onClick={onNext} {...btnProps} >{nextButtonText}</NextButton>
                </Container>
            </ConditionalContainer>
        </CenteredCardScreen>
    )
};

export default strappedConnected(
    RegisterPartnerScreen,
    {
        form: setFormField.selectors.getForm,
    },
    {
        setUserInformation: (input, callback) => setUserInformation.action({ input, callback }),
        setPartnerInformation: (input, callback) => createEntity.action({ input, callback }),
        clearForm: () => clearForm.action(),
    },
    ({ form, setUserInformation, setPartnerInformation, clearForm }) => {
        const handleSubmitPartnerForm = useCallback((input) => {
            const payload = parsePayload({
                ...form,
                kind: PARTNER_KINDS.PEER
            })
            setPartnerInformation(payload)
            clearForm()
        }, [form]);

        const handleUserInformationForm = useCallback((input) => {
            setUserInformation(
                parseUserInformationInput(form)
            )
            clearForm()
        }, [form]);

        return {
            form,
            setUserInformation: handleUserInformationForm,
            setPartnerInformation: handleSubmitPartnerForm,
        }
    }
);